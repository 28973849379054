import React, { useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import ToastifyService from "../../_common/ToastifyService";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function CreateNewGroup(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const createTaskGroup = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "CreateTask",
      });
      const userGroupList = await axiosPrivate.post(`Users/create-user-group`, {
        ...formData,
        status: Commonfield.status.active,
        createdBy: auth?.id,
      });
      props?.setUserGroupList(userGroupList?.data?.result);
      props?.closeFunction();
      ToastifyService.success("Group Created Successfully");
    } catch (err) {
      // console.error(err);
      console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "CreatePriority",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "CreatePriority",
      });
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Task Group
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>
                  Group Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Group Name"
                  name="groupName"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Description"
                  name="groupDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "CreateTask"}
            onClick={createTaskGroup}
          >
            {loading.isLoading && loading?.value === "CreateTask" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Create</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
