import React from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";

const LoadingSpinner = ({ color, size, type }) => {
  return (
    <>
      {type === "TailSpin" ? (
        <div style={{ display: "inline-block" }}>
          <TailSpin color={color} height={size} />
        </div>
      ) : type === "ThreeDots" ? (
        <div style={{ display: "inline-block" }}>
          <ThreeDots color={color} height={size} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingSpinner;
