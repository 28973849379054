import React, { useContext, useEffect, useMemo, useState } from "react";
import ToastifyService from "../../../_common/ToastifyService";
import AMDStatusFormModalDynamic from "./AMDStatusFormModalDynamic";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import SOPFileViewModalDynamic from "./SOPFileViewModalDynamic";
import AuthContext from "../../../../context/AuthProvider";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import LoadingSpinner from "../../../_common/LoadingSpinner";

function CaseDispositionDynamic({
  nodes,
  edges,
  dynamicMapping,
  nodeData,
  toggleStates,
  setToggleStates,
  formData,
  setFormData,
  dropdownSelections,
  setDropdownSelections,
  multiDropdownSelections,
  setMultiDropdownSelections,
  decisionTreeList,
  taskDetailsRef,
  setPendingTasks,
  setTeamLeadQueries,
  setParkedTasks,
  setMyTask,
  resetForm
}) {
  const [checkboxes, setCheckboxes] = useState({});
  const [dynamicNotesParagraph, setDynamicNotesParagraph] = useState("");
  const [showAMDStatusFormModal, setShowAMDStatusFormModal] = useState(false);
  // const [showSOPFileViewModal, setSOPFileViewModal] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const { auth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState({ loading: false, value: "" });

  const [AMDFormData, setAMDFormData] = useState({});
  useEffect(() => {
    const initialToggleStates = [];
    const initialFormData = {};
    const initialDropdownSelections = {};
    const initialCheckboxes = {};
    nodes?.forEach((node) => {
      const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
      if (node.type === "00000000-0000-0000-0000-000000000001") {
        if (node.id === "node_0") {
          initialDropdownSelections[node.id] = node?.data?.componentValues?.map(
            (cmpval) => ({
              id: cmpval.id,
              label: cmpval.name,
            })
          )[0];
          setDropdownSelections(initialDropdownSelections);
        }
      }
      if (nodeInData) {
        if (
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f01" ||
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f02" ||
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f03" ||
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f04" ||
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f05" ||
          node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f06"
        ) {
          initialFormData[node.id] = nodeInData?.compValue;
          setFormData(initialFormData);
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
          if (nodeInData?.compValue) {
            initialDropdownSelections[node.id] = node?.data?.componentValues
              ?.map((option) => ({
                value: option.id,
                label: option.name,
              }))
              .find(
                (cmpval) =>
                  cmpval.label.toLowerCase() ===
                  nodeInData?.compValue.toLowerCase()
              );
            setDropdownSelections(initialDropdownSelections);
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
          if (nodeInData?.compValue) {
            const selectedValues = nodeInData?.compValue
              .split(",")
              .map((val) => val.trim().toLowerCase());
            initialDropdownSelections[node.id] = node?.data?.componentValues
              ?.filter((option) =>
                selectedValues.includes(option.name.toLowerCase())
              )
              .map((option) => ({
                value: option.id,
                label: option.name,
              }));

            setMultiDropdownSelections(initialDropdownSelections);
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
          if (nodeInData?.compValue) {
            const componentOptions = node?.data?.componentValues?.map(
              (option) => ({
                value: option.id,
                label: option.name,
              })
            );
            const matchedOption = componentOptions?.find(
              (cmpval) =>
                cmpval?.label?.toLowerCase() ===
                nodeInData?.compValue?.toLowerCase()
            );
            if (matchedOption) {
              const index = componentOptions?.indexOf(matchedOption);
              initialToggleStates[node.id] = {
                ...matchedOption,
                boolVal: index === 0 ? true : false,
              };
              setToggleStates(initialToggleStates);
            }
          }
        }
      }
    });
    setIsDataUpdated(true);
  }, [nodes, nodeData]);

  const handleInputChange = (nodeId, value) => {
    const updatedFormData = { ...formData, [nodeId]: value };
    const childConnections = dynamicMapping[nodeId]?.connections || [];
    childConnections.forEach(({ target }) => {
      updatedFormData[target] = "";
    });
    setFormData(updatedFormData);
  };

  const handleDropdownChange = (nodeId, value) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [nodeId]: value,
    }));
  };

  const handleMultiDropdownChange = (nodeId, value) => {
    setMultiDropdownSelections((prev) => ({
      ...prev,
      [nodeId]: value,
    }));
  };

  const handleToggleChange = (nodeId, isChecked) => {
    setToggleStates((prev) => ({
      ...prev,
      [nodeId]: isChecked,
    }));
  };

  const handleCheckboxChange = (event, label, nodeId) => {
    const { checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        [label]: checked,
      },
    }));
  };

  const renderNode = (node) => {
    const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
    const showAddButton = nodeInData && nodeInData?.addStatus;
    const addButton = (
      <Button
        variant="outline-primary"
        className="btn-sm"
        onClick={() => {
          setAMDFormData({ ...nodeInData, node });
          setShowAMDStatusFormModal(true);
        }}
      >
        Manage
      </Button>
    );
    const showInformation = nodeInData && nodeInData?.isSOPFile;
    const infoIcon = (
      <Button
        variant="outline-primary"
        className="btn-sm mx-3"
        onClick={() => {
          setAMDFormData({ ...nodeInData, node });
          // setSOPFileViewModal(true);
        }}
      >
        <i
          className="ri-information-line"
          style={{ cursor: "pointer", height: "70px" }}
        ></i>{" "}
        <span>Open SOP</span>
      </Button>
    );
    switch (node?.data?.componentTypeId) {
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f05":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label}
                    {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <ButtonGroup>
                {node?.data?.componentValues?.map((option, index) => (
                  <Button
                    key={`${option?.id}-${node?.id}`}
                    variant={
                      option?.name?.toLowerCase() ===
                      toggleStates[node.id]?.label?.toLowerCase()
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={(e) => {
                      const isFirstButtonClicked = index === 0;
                      handleToggleChange(node?.id, {
                        id: option?.id,
                        label: option.name,
                        boolVal: isFirstButtonClicked,
                      });
                    }}
                  >
                    <input
                      type="radio"
                      name={`toggle-${node.id}`}
                      className="btn-check"
                      autoComplete="off"
                      checked={
                        option?.name?.toLowerCase() ===
                        toggleStates[node.id]?.label?.toLowerCase()
                      }
                      readOnly
                    />
                    {option?.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f02":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                onChange={(selected) => handleDropdownChange(node.id, selected)}
                isSearchable={true}
                options={node?.data?.componentValues?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                name={`dropdown-${node.id}`}
                value={dropdownSelections[node.id] || null}
              />
            </Col>
          </Row>
        );
      case "00000000-0000-0000-0000-000000000001":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    Status {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                isSearchable={true}
                options={decisionTreeList?.map((option) => ({
                  value: option.id,
                  label: option.dispositionName,
                }))}
                isDisabled={true}
                name={`dropdown-${node.id}`}
                value={dropdownSelections[node.id] || null}
                onChange={(selected) => handleDropdownChange(node.id, selected)}
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f03":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                options={node?.data?.componentValues?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                isSearchable={true}
                isMulti={true}
                name={`multiselect-dropdown-${node.id}`}
                value={multiDropdownSelections[node.id] || []}
                onChange={(selected) =>
                  handleMultiDropdownChange(node.id, selected)
                }
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f04":
        return (
          <Row>
            <Form>
              <Col md={12}>
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
              </Col>
              <Col>
                {node?.data?.componentValues?.map((type) => (
                  <div className="mb-" key={`${type.id}-${node.id}`}>
                    <Form.Check
                      name={`multiselect-checkbox-${node.id}`}
                      label={type.name}
                      checked={checkboxes[node.id]?.[type.name] || false}
                      onChange={(e) =>
                        handleCheckboxChange(e, type.name, node.id)
                      }
                      type="checkbox"
                    />
                  </div>
                ))}
              </Col>
            </Form>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f01":
        switch (node?.data?.datatypeId) {
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f01":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="text"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f02":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="number"
                  value={formData[node.id] || 0}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f03":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="date"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f04":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="url"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f05":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="email"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f06":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="tel"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  // const generateForm = useMemo(() => {
  //   let dynamicNotes = "";
  //   const renderedFields = [];
  //   if (isDataUpdated) {
  //     const renderNodeWithChildren = (node) => {
  //       renderedFields.push(renderNode(node));

  //       const connectedEdges = edges?.filter((edge) => edge.source === node.id);

  //       connectedEdges?.forEach((edge) => {
  //         let shouldRenderChild = false;

  //         if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
  //           if (dropdownSelections[node.id]?.label === edge?.label) {
  //             shouldRenderChild = true;
  //             const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
  //             if (nodeInData) {
  //               nodeInData.compValue = dropdownSelections[node.id]?.label;
  //             }
  //           }
  //         } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
  //           if (toggleStates[node.id]?.boolVal) {
  //             if (edge?.sourceHandle === "checked") {
  //               shouldRenderChild = true;
  //             }
  //           } else {
  //             if (edge?.sourceHandle === "unchecked") {
  //               shouldRenderChild = true;
  //             }
  //           }
  //           const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
  //           if (nodeInData) {
  //             nodeInData.compValue = toggleStates[node.id]?.label;
  //           }
  //         } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
  //           if (checkboxes[node.id]?.[edge.label]) {
  //             shouldRenderChild = true;
  //           }
  //           const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
  //           if (nodeInData) {
  //             nodeInData.compValue = checkboxes[node.id]?.[edge.label];
  //           }
  //         } else if (node?.type === "00000000-0000-0000-0000-000000000001") {
  //           if (node?.id === "node_0") {
  //             shouldRenderChild = true;
  //           }
  //           const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
  //           if (nodeInData) {
  //             nodeInData.isParent = true;
  //           }
  //         }
  //         if (shouldRenderChild) {
  //           const targetNode = nodes?.find((n) => n.id === edge.target);
  //           if (targetNode) {
  //             const nodeInData = nodeData?.find((n) => n.nodeId === targetNode.id);
  //             let shouldRenderChild1 = false;
  //             if (targetNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
  //               shouldRenderChild1 = true;
  //               if (nodeInData) {
  //                 nodeInData.compValue =
  //                   multiDropdownSelections[targetNode.id]
  //                     .map(item => item.label)  // Extract the label values
  //                     .join(", ");
  //               }
  //             } else if (
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f01" ||
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f02" ||
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f03" ||
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f04" ||
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f05" ||
  //               targetNode.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f06"
  //             ) {
  //               shouldRenderChild1 = true;
  //               if (nodeInData) {
  //                 nodeInData.compValue = formData[targetNode.id];
  //               }
  //             }
  //             if (shouldRenderChild1) {
  //               if (
  //                 nodeInData &&
  //                 nodeInData.notes &&
  //                 typeof nodeInData.notes === "string" &&
  //                 nodeInData.notes.includes("{data}")
  //               ) {
  //                 let updatedNote = nodeInData.notes.replace(
  //                   "{data}",
  //                   nodeInData.compValue || ""
  //                 );

  //                 dynamicNotes += `${updatedNote} `;
  //               }
  //             } else {
  //               const nodeInData = nodeData?.find(
  //                 (n) => n.nodeId === targetNode.id
  //               );
  //               if (
  //                 nodeInData &&
  //                 nodeInData.notes &&
  //                 typeof nodeInData.notes === "string" &&
  //                 nodeInData.notes.includes("{data}")
  //               ) {
  //                 let updatedNote = nodeInData.notes.replace(
  //                   "{data}",
  //                   nodeInData.compValue || ""
  //                 );

  //                 dynamicNotes += `${updatedNote} `;
  //               }
  //             }
  //             renderNodeWithChildren(targetNode);
  //           }
  //         }
  //       });
  //     };
  //     nodes.forEach((node) => {
  //       const isOrphanedNode = edges?.every(
  //         (edge) => edge.target !== node.id && edge.source !== node.id
  //       );
  //       const isParentNode = edges?.every((edge) => edge.target !== node.id);

  //       if (isParentNode || isOrphanedNode) {
  //         renderNodeWithChildren(node);
  //       }
  //     });
  //     return { renderedFields, dynamicNotes };
  //   }
  // }, [
  //   edges,
  //   nodes,
  //   nodeData,
  //   dropdownSelections,
  //   toggleStates,
  //   checkboxes,
  //   multiDropdownSelections,
  //   formData,
  //   isDataUpdated,
  // ]);
  const generateForm = useMemo(() => {
    let dynamicNotes = "";
    const renderedFields = [];
    const jsonBody = {}; // Initialize an empty object to store the JSON data

    if (isDataUpdated) {
      const renderNodeWithChildren = (node) => {
        renderedFields.push(renderNode(node));

        const connectedEdges = edges?.filter((edge) => edge.source === node.id);

        connectedEdges?.forEach((edge) => {
          let shouldRenderChild = false;

          if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
            if (dropdownSelections[node.id]?.label === edge?.label) {
              shouldRenderChild = true;
              const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
              if (nodeInData) {
                nodeInData.compValue = dropdownSelections[node.id]?.label;
              }
            }
          } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
            if (toggleStates[node.id]?.boolVal) {
              if (edge?.sourceHandle === "checked") {
                shouldRenderChild = true;
              }
            } else {
              if (edge?.sourceHandle === "unchecked") {
                shouldRenderChild = true;
              }
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.compValue = toggleStates[node.id]?.label;
            }
          } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
            if (checkboxes[node.id]?.[edge.label]) {
              shouldRenderChild = true;
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.compValue = checkboxes[node.id]?.[edge.label];
            }
          } else if (node?.type === "00000000-0000-0000-0000-000000000001") {
            if (node?.id === "node_0") {
              shouldRenderChild = true;
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.isParent = true;
            }
          }
          if (node.id === "node_0") {
            const targetNode = nodes?.find((n) => n.id === node.id);
            if (targetNode) {
              jsonBody["Status"] = targetNode?.data?.label;
            }
          }
          if (shouldRenderChild) {
            const targetNode = nodes?.find((n) => n.id === edge.target);
            if (targetNode) {
              const nodeInData = nodeData?.find(
                (n) => n.nodeId === targetNode.id
              );
              let shouldRenderChild1 = false;
              if (targetNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = multiDropdownSelections[targetNode.id]
                    .map((item) => item.label) // Extract the label values
                    .join(", ");
                }
              } else if (
                [
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f01",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f02",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f03",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f04",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f05",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f06",
                ].includes(targetNode.type)
              ) {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = formData[targetNode.id];
                }
              }
              if (shouldRenderChild1) {
                if (
                  nodeInData &&
                  nodeInData.notes &&
                  typeof nodeInData.notes === "string" &&
                  nodeInData.notes.includes("{data}")
                ) {
                  let updatedNote = nodeInData.notes.replace(
                    "{data}",
                    nodeInData.compValue || ""
                  );
                  jsonBody[targetNode?.data?.label] = nodeInData.compValue; // Add to JSON
                  dynamicNotes += `${updatedNote} `;
                }
              } else {
                const nodeInData = nodeData?.find(
                  (n) => n.nodeId === targetNode.id
                );
                if (
                  nodeInData &&
                  nodeInData.notes &&
                  typeof nodeInData.notes === "string" &&
                  nodeInData.notes.includes("{data}")
                ) {
                  let updatedNote = nodeInData.notes.replace(
                    "{data}",
                    nodeInData.compValue || ""
                  );
                  jsonBody[targetNode?.data?.label] = nodeInData.compValue;
                  dynamicNotes += `${updatedNote} `;
                }
              }
              renderNodeWithChildren(targetNode);
            }
          }
        });
      };
      nodes.forEach((node) => {
        const isOrphanedNode = edges?.every(
          (edge) => edge.target !== node.id && edge.source !== node.id
        );
        const isParentNode = edges?.every((edge) => edge.target !== node.id);

        if (isParentNode || isOrphanedNode) {
          renderNodeWithChildren(node);
        }
      });
      return { renderedFields, dynamicNotes, jsonBody }; // Include JSON body in return
    }
  }, [
    edges,
    nodes,
    nodeData,
    dropdownSelections,
    toggleStates,
    checkboxes,
    multiDropdownSelections,
    formData,
    isDataUpdated,
  ]);

  useEffect(() => {
    if (isDataUpdated) {
      setDynamicNotesParagraph(generateForm.dynamicNotes.trim());
    }
  }, [isDataUpdated, generateForm?.dynamicNotes]);

  const handleSubmitDisposition = async () => {
    setLoading({ loading: true, value: "handleSubmitDisposition" });
    const { jsonBody } = generateForm;

    const formObj = {
      caseId: taskDetailsRef?.current?.id,
      visitId: taskDetailsRef?.current?.visitfid,
      dispositionBody: JSON.stringify(jsonBody),
      dispositionRemark: dynamicNotesParagraph,
      modifiedBy: auth.id,
    };
    console.log(formObj);
    try {
      const response = await axiosPrivate.post(
        "Task/case-disposition",
        formObj
      );
      setPendingTasks(response.data.result.pendingTasks);
      setTeamLeadQueries(response?.data?.result?.tlQueries);
      setParkedTasks(response?.data?.result?.parkedTasks);
      setMyTask(response?.data?.result);
    } catch (error) {
      console.error(error);
      setLoading({ loading: false, value: "handleSubmitDisposition" });
    } finally {
      setLoading({ loading: false, value: "handleSubmitDisposition" });
      resetForm();
      ToastifyService.success("Case Disposed SuccessFully");
    }
  };

  return (
    <React.Fragment>
      {showAMDStatusFormModal && (
        <AMDStatusFormModalDynamic
          show={showAMDStatusFormModal}
          closeFunction={setShowAMDStatusFormModal}
          AMDFormData={AMDFormData}
        />
      )}
      {/* {showSOPFileViewModal && (
        <SOPFileViewModalDynamic
          show={showSOPFileViewModal}
          closeFunction={setSOPFileViewModal}
          AMDFormData={AMDFormData}
        />
      )} */}
      <div className="overflow-y p-3" style={{ height: "calc(100vh - 330px)" }}>
        {generateForm?.renderedFields}
      </div>

      <div
        className="offcanvas-footer d-block position-absolute botton-0 h-auto"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="mb-4">
          <label for="exampleFormControlTextarea1" class="form-label">
            Automated Notes
          </label>
          <textarea
            style={{ borderColor: "#000" }}
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Enter message here..."
            value={dynamicNotesParagraph}
            onChange={(e) => setDynamicNotesParagraph(e.target.value)}
          ></textarea>
        </div>
        <div className="text-right">
          {/* <Button variant="primary" type="submit" onClick={handleSubmitDisposition}>
            Submit
          </Button> */}

          <Button
            variant="primary"
            type="submit"
            disabled={
              loading.loading && loading?.value === "handleSubmitDisposition"
            }
            onClick={handleSubmitDisposition}
          >
            {loading.loading &&
            loading?.value === "handleSubmitDisposition" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CaseDispositionDynamic;
