import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import { TailSpin } from "react-loader-spinner";
import * as XLSX from "xlsx";

function SOPFileViewModal(props) {
  const { AMDFormData } = props;
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (props?.show) {
      if (AMDFormData?.SOPFile) {
        const file = AMDFormData.SOPFile;
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setFileType(getFileType(file.type));
        setExcelData([]);
        if (
          file.type.includes("sheet") ||
          file.type.includes("excel") ||
          file.type.includes("csv")
        ) {
          readExcelOrCSVFile(blob, file.type);
        } else {
          setLoading(false);
        }
      }
    }
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [props?.show]);

  const readExcelOrCSVFile = (blob, fileType) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (fileType.includes("csv")) {
        const textData = e.target.result;
        const csvData = textData.split("\n").map((row) => row.split(","));
        setExcelData(csvData);
      } else {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setExcelData(jsonData);
      }
      setLoading(false);
    };
    if (fileType.includes("csv")) {
      reader.readAsText(blob);
    } else {
      reader.readAsArrayBuffer(blob);
    }
  };

  const getFileType = (mimeType) => {
    const typeMap = {
      "application/pdf": "pdf",
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/svg+xml": "svg",
      "text/html": "html",
      "audio/mpeg": "mp3",
      "video/mp4": "mp4",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.document":
        "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx",
      "text/csv": "csv",
    };
    return typeMap[mimeType] || null;
  };
  if (loading) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={props.closeFunction}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }
  if (!fileType) {
    return (
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={props.closeFunction}
          placement="end"
          className="w-25"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <h6 className="d-inline-block">
                {AMDFormData?.node?.data?.label}
              </h6>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>Unsupported file type: {AMDFormData.SOPFile.type}</p>
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">{AMDFormData?.node?.data?.label}</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ height: "100%", overflowY: "auto" }}>
            {excelData.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    {excelData[0].map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div style={{ height: "100%" }}>
                {fileType === "html" ? (
                  <iframe
                    src={fileUrl}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : fileType === "mp3" ? (
                  <audio controls>
                    <source src={fileUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                ) : fileType === "mp4" ? (
                  <video controls style={{ width: "100%", height: "100%" }}>
                    <source src={fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <FileViewer
                    fileType={fileType}
                    filePath={fileUrl}
                    onError={(e) => console.log("Error rendering file", e)}
                  />
                )}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}

export default SOPFileViewModal;
