import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function ClaimInformation(props) {
  return (
    <React.Fragment>
      <div className="claim-information-wrapper">
        <Card className="mb-3">
          <span className="divider-name">Insurance Details</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="g-3">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Bill Price</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.billprice === null ||
                  props?.taskDetails?.billprice === ""
                    ? "-"
                    : props?.taskDetails?.billprice}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Allowed Amount</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.allowedamount === null ||
                  props?.taskDetails?.allowedamount === ""
                    ? "-"
                    : props?.taskDetails?.allowedamount}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Most Recent Deposit Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {" "}
                  {props?.taskDetails?.mostrecentdepositdate ===
                  "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.taskDetails?.mostrecentdepositdate
                      ).toLocaleString()}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Insurance Payment Amount</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.totalinsurancepaymentamount === null ||
                  props?.taskDetails?.totalinsurancepaymentamount === ""
                    ? "-"
                    : props?.taskDetails?.totalinsurancepaymentamount}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Patient Responsibility</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.patientresponsibility === null ||
                  props?.taskDetails?.patientresponsibility === ""
                    ? "-"
                    : props?.taskDetails?.patientresponsibility}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Original Claim Submit Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.originalclaimsubmitdate ===
                  "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.taskDetails?.originalclaimsubmitdate
                      ).toLocaleString()}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* <Card className="mb-3">
          <span className="divider-name">Provider Details</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Refering Provider Name</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.referringProvider}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Service Location</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.serviceLocation}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">Claim</span>
          <Card.Body className="pt-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Adjustment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.adjustment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Insurance Payment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.insurancePayment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Patient Payment</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.patientPayment}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Current Balace</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.currentBalance}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Insurance Balace</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.insuranceBalance}
                </h4>
              </Col>

              <Col xl={2}>
                <h5 className="fs-14 mb-1">Patient Balance</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.patientBalance}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">First Bill Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.firstBillDate === "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.taskDetails?.firstBillDate
                      ).toLocaleString()}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Bill Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lastBillDate === "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(props?.taskDetails?.lastBillDate).toLocaleString()}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <span className="divider-name">Denial/Correspondence</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Denial Category</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.denialCategory}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Denial date </h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lastDenialDate === "0001-01-01T00:00:00"
                              ? "-"
                              : new Date(
                                props?.taskDetails?.lastDenialDate
                                ).toLocaleString()}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}

        <Card className="mb-3">
          <span className="divider-name">History</span>
          <Card.Body className="pt-4">
            <Row className="gap-4">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_ACTION_DATE === "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.taskDetails?.lasT_ACTION_DATE
                      ).toLocaleString()}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Status</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_STATUS === null ||
                  props?.taskDetails?.lasT_STATUS === ""
                    ? "-"
                    : props?.taskDetails?.lasT_STATUS}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Sub status</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_SUB_STATUS === null ||
                  props?.taskDetails?.lasT_SUB_STATUS === ""
                    ? "-"
                    : props?.taskDetails?.lasT_SUB_STATUS}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action </h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_ACTION === null ||
                  props?.taskDetails?.lasT_ACTION === ""
                    ? "-"
                    : props?.taskDetails?.lasT_ACTION}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Action Mode</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_MODE === null ||
                  props?.taskDetails?.lasT_MODE === ""
                    ? "-"
                    : props?.taskDetails?.lasT_MODE}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
