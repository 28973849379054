import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ToastifyService from "../../_common/ToastifyService";

function AMDStatusFormModal(props) {
  const axiosPrivate = useAxiosPrivate();
  const [amdStatusId, setAMDStatusId] = useState(null);
  const [AMDStatusFormList, setAMDStatusFormList] = useState([]);
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [formData, setFormData] = useState({});
  const AMDFormFields = JSON.parse(sessionStorage.getItem("amdStatuscommonField"));
  console.log("formSubmissions", formSubmissions);
  useEffect(() => {
    if (props?.show) {
      if (props?.AMDFormData.amdStatus || amdStatusId === null) {
        setAMDStatusId(props?.AMDFormData.amdStatus);
      }
    }
  }, [props?.show]);

  useEffect(() => {
    if (props?.show && amdStatusId) {
      getAMDStatusFormList(amdStatusId);
      loadExistingFormData(amdStatusId);
    }
  }, [amdStatusId]);

  const getAMDStatusFormList = async (amdStatusId) => {
    try {
      setLoading({ isLoading: true, value: "getAMDStatusFormList" });
      const AMDStatusFormListData = await axiosPrivate.get(
        `Setting/get-AMD-Status-Details/${amdStatusId}`
      );
      console.log("AMDStatusFormListData",AMDStatusFormListData.data)
      setAMDStatusFormList(AMDStatusFormListData.data);
    } catch (err) {
      setLoading({ isLoading: false, value: "getAMDStatusFormList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getAMDStatusFormList" });
    }
  };

  const loadExistingFormData = (nodeId) => {
    const existingSubmission = formSubmissions.find(
      (submission) => submission.nodeId === nodeId
    );
    if (existingSubmission) {
      setFormData(existingSubmission.data);
    } else {
      setFormData({});
    }
  };

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   const submittedData = {};
  //   AMDStatusFormList?.amdFormMasterWithAMDStatus?.forEach((field) => {
  //     submittedData[field.formMasterId] = e.target[field.formMasterId]?.value;
  //   });
  //   const existingNodeIndex = formSubmissions.findIndex(
  //     (submission) => submission.nodeId === amdStatusId
  //   );

  //   if (existingNodeIndex >= 0) {
  //     const updatedSubmissions = [...formSubmissions];
  //     updatedSubmissions[existingNodeIndex] = {
  //       nodeId: props?.AMDFormData?.node?.id,
  //       data: submittedData,
  //     };
  //     setFormSubmissions(updatedSubmissions);
  //   } else {
  //     setFormSubmissions((prevSubmissions) => [
  //       ...prevSubmissions,
  //       { nodeId: amdStatusId, data: submittedData },
  //     ]);
  //   }
  //   ToastifyService.success("Saved!");
  //   props.closeFunction();
  //   setAMDStatusId(null);
  //   setAMDStatusFormList(null);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const renderFormField = (field) => {
    const { datatypeId, formLabel, isList, listId, isRequired } = field;
    if (isList) {
      return (
        <Form.Group className="mb-3" controlId={field.formMasterId}>
          <Form.Label>{formLabel}</Form.Label>
          <Form.Select
            required={isRequired}
            name={field.formMasterId}
            value={formData[field.formMasterId] || ""}
            onChange={handleInputChange}
          >
            <option value="">Select an option</option>
            {AMDFormFields?.find(
              (amdff) => amdff.id === listId
            )?.fieldValues?.map((fv) => (
              <option value={fv.id} key={fv.id}>
                {fv.value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      );
    }

    switch (datatypeId) {
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f03":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="date"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f02":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="number"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f07":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      case "4e0073a0-2339-4acf-bbd7-6b265bed7f01":
        return (
          <Form.Group className="mb-3" controlId={field.formMasterId}>
            <Form.Label>{formLabel}</Form.Label>
            <Form.Control
              type="text"
              required={isRequired}
              name={field.formMasterId}
              value={formData[field.formMasterId] || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        );
      default:
        return null;
    }
  };
  const handleSuccess = () => {
    ToastifyService.success("Saved!");
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() => {
          props.closeFunction();
          setAMDStatusId(null);
          setAMDStatusFormList(null);
          setFormData({});
        }}
        placement="end"
        className="w-25"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h6 className="d-inline-block">AMD Status</h6> :{" "}
            <b>{AMDStatusFormList?.tabName}</b>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {loading.isLoading && loading.value === "getAMDStatusFormList" ? (
              <div className="spinner-loader-sidenav">
                <TailSpin color="#0d6efd" height={30} />
              </div>
            ) : (
              <>
                {AMDStatusFormList?.amdFormMasterWithAMDStatus?.map((field) => (
                  <React.Fragment key={field.formMasterId}>
                    {renderFormField(field)}
                  </React.Fragment>
                ))}
              </>
            )}
          </Form>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            type="submit"
            onClick={handleSuccess}
            // {handleFormSubmit}
          >
            Submit
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default AMDStatusFormModal;
