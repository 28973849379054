import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import ShimmerLoader from "../_common/ShimmerLoader";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Header from "../../layouts/Header";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingSpinner from "../_common/LoadingSpinner";
import workQueueExcel from "../../assets/excel/WorkQueue.xlsx";
import ToastifyService from "../_common/ToastifyService";
import Commonfield from "../../infrastructure/core/Commonfield";

function DecisionTreeManage() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [decisionTreeList, setDecisionTreeList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const getWorkflowDetails = async (id) => {
    navigate(`/decision-tree-creator/${id}`);
  };
  const filteredData = decisionTreeList?.filter((item) => {
    return (
      item?.dispositionName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.statusDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdBy?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.createdOn)
        .toLocaleString()
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  const columns = [
    {
      name: "Work Queue Name",
      selector: (row) => row.dispositionName,
      sortable: true,
      cell: (row) => <div>{row.dispositionName}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.statusDesc,
      sortable: true,
      cell: (row) => (
        <div>
          <span
            class={`badge badge-pill fs-12 ${
              row.status === Commonfield.workQueueTypeStatus.available
                ? "bg-success"
                : "bg-danger"
            }`}
          >
            {row.statusDesc}
          </span>
        </div>
      ),
    },
    {
      name: "Created By",
      selector: (row) => row?.createdByName,
      sortable: true,
      cell: (row) => <div>{row?.createdByName}</div>,
    },
    {
      name: "Created On",
      selector: (row) => row?.createdOn,
      sortable: true,
      cell: (row) => <div>{new Date(row?.createdOn).toLocaleString()}</div>,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-2">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              onClick={() => getWorkflowDetails(row.id)}
            >
              <span>
                {" "}
                {row.status === Commonfield.workQueueTypeStatus.available
                  ? "Edit"
                  : "Create"}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getDecisionTreeList();
  }, []);
  const getDecisionTreeList = async () => {
    try {
      setLoading({ isLoading: true, value: "getDecisionTreeList" });
      const decisionTreeListData = await axiosPrivate.get(
        `Setting/get-disposition-list`
      );
      console.log("DecisionTreeListData", decisionTreeListData?.data);
      setDecisionTreeList(decisionTreeListData?.data);
    } catch (err) {
      console.error("Error fetching decision tree list:", err);
    } finally {
      setLoading({ isLoading: false, value: "getDecisionTreeList" });
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const uploadWorkQueue = async (e) => {
    const file = e.target.files[0];
    setLoading({
      isLoading: true,
      value: "uploadWorkQueue",
    });

    if (
      file &&
      (file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel")
    ) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadWorkQueueRes = await axiosPrivate.post(
          `Setting/upload-work-queue`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        getDecisionTreeList();
        const { existingRecords, insertedCount } = uploadWorkQueueRes.data;
        const totalRecordsProcessed =
          (existingRecords?.length || 0) + insertedCount;
        const message = `Total Records Processed: ${totalRecordsProcessed}, Records Inserted: ${insertedCount}, Records Already Existing: ${
          existingRecords?.length || 0
        }`;
        ToastifyService.success(message);
      } catch (error) {
        console.error("Error during upload:", error);
        ToastifyService.error("Oops! Something Went Wrong");
      } finally {
        setLoading({
          isLoading: false,
          value: "uploadWorkQueue",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Work Queue</h4>
          </div>
        </div>

        <Card className="card-one">
          <Card.Header className="justify-content-between align-items-center">
            <div>
              <Form.Control
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                className="wt-250"
              />
            </div>

            <div className="d-flex gap-2">
              <div className="dropdown d-grid ">
                <label
                  htmlFor="upload-input"
                  className="btn btn-primary d-flex align-items-center gap-1 justify-content-center"
                  disabled={
                    loading.isLoading && loading?.value === "uploadWorkQueue"
                  }
                >
                  {loading.isLoading && loading?.value === "uploadWorkQueue" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    <>
                      <i className="ri-upload-line fs-16 lh-1"></i>
                      <span className="align-middle ms-2">
                        Upload Work Queue
                      </span>
                      <input
                        id="upload-input"
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        style={{ display: "none" }}
                        onChange={(e) => uploadWorkQueue(e)}
                      />
                    </>
                  )}
                </label>
              </div>
              <a href={workQueueExcel} download="Template_WorkQueue.xlsx">
                <Button variant="outline-primary">
                  {" "}
                  <i className="ri-download-line fs-16 lh-1"></i> Download
                  Template
                </Button>
              </a>
              {/* <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/decision-tree-creator/create");
                    }}
                  >
                    Create
                  </Button>
                </div> */}
            </div>
          </Card.Header>
          <Card.Body className="common-data-table">
            {loading.isLoading && loading.value === "getDecisionTreeList" ? (
              <ShimmerLoader
                colNames={columns?.map((column) => column.name)}
                colsCount={1}
              />
            ) : (
              <DataTable columns={columns} data={filteredData} pagination />
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default DecisionTreeManage;
