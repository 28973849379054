import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col} from "react-bootstrap";
import Select from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { parseSQL } from "react-querybuilder/parseSQL";
import useAuth from "../../../hooks/useAuth";
import TestQueryTable from "../test-query.table";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default React.memo(function UpdateRule(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const taskRuleStatus = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "TRSTTS")?.fieldValues;
  const [userGroupList, setUserGroupList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [query, setQuery] = useState(null);
  const [showTestQueryTable, setShowTestQueryTable] = useState({
    showBool: false,
    queryResponse: [],
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [testQuery, setTestQuery] = useState("");
  const operators = [
    { name: "=", label: "=" },
    { name: "<", label: "<" },
    { name: ">", label: ">" },
    { name: "<=", label: "<=" },
    { name: ">=", label: ">=" },
    { name: "<>", label: "<>" },
    { name: "contains", label: "Contains" },
    { name: "beginsWith", label: "Begins with" },
    { name: "endsWith", label: "Ends with" },
  ];
  const fields = [
    {
      name: "LastBilledPayer",
      label: "Last Billed payer",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastBilledPayerGovtComm",
      label: "Last Billed payer Govt/Comm",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastBilledPayerFC",
      label: "Last Billed payer FC",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "TestType",
      label: "Test Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "TestSubType",
      label: "Test Sub Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "InsuranceBalance",
      label: "Insurance Balance",
      inputType: "number",
      operators: operators.filter((op) =>
        ["<", ">", "=", "<=", ">="].includes(op.name)
      ),
    },
    {
      name: "DenialCategory",
      label: "Denial Category",
      operators: operators.filter((op) =>
        [
          "contains",
          "beginsWith",
          "<>",
          "<",
          ">",
          "=",
          "<=",
          ">=",
          "endsWith",
        ].includes(op.name)
      ),
    },
    {
      name: "LastStatus",
      label: "Last Status",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastAction",
      label: "Last Action",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastActionMode",
      label: "Last Action Mode",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastActionTaskQue",
      label: "Last Action Task Que",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "FollowUpDateBucket",
      label: "F-Up Date Bucket",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "WorkQueueType",
      label: "Work Queue Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "DOSBucket",
      label: "DOS Bucket",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
  ];
  useEffect(() => {
    getUserGroupList();
    getTaskRuleDetails(props.taskRuleId);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const getUserGroupList = async () => {
    try {
      const userGroupListData = await axiosPrivate.get(`Users/user-groups`);
      setUserGroupList(
        userGroupListData?.data?.result?.map((usrgrp) => ({
          label: usrgrp.groupName,
          value: usrgrp.id,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };
  const getTaskRuleDetails = async (taskRuleId) => {
    try {
      const taskRuleData = await axiosPrivate.get(
        `TaskEngine/task-buckets/${taskRuleId}`
      );
      setFormData(taskRuleData?.data?.result);
      const parsedValue = parseSQL(taskRuleData?.data?.result?.taskBucketQuery);

      setQuery(
        parsedValue
          ? parsedValue
          : {
              combinator: "and",
              rules: [],
            }
      );
    } catch (err) {
      console.error(err);
      setQuery({
        combinator: "and",
        rules: [],
      });
    }
  };
  const handleQueryChange = (query) => {
    const generatedSqlQuery = formatQuery(query, "sql");
    setTestQuery(`select * from example where ${generatedSqlQuery}`);
    setQuery(query);
  };
  const updateTaskRule = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "updateTaskRule",
      });
      const taskRuleData = await axiosPrivate.post(
        `TaskEngine/update-task-bucket`,
        {
          ...formData,
          modifiedBy: auth?.id,
          taskBucketQuery:
            "select * from inventories where" + formatQuery(query, "sql"),
        }
      );
      console.log("formData", formData);
      props?.setTaskEnginePublishList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.published
        )
      );
      props?.setTaskEngineDraftList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.draft
        )
      );
      ToastifyService.success("Rule Updated Successfully");
      props?.SetShowUpdateRule({ showBool: false, taskRuleId: "" });
    } catch (err) {
      console.error(err);
      setQuery({
        combinator: "and",
        rules: [],
      });
      setLoading({
        isLoading: false,
        value: "updateTaskRule",
      });
      ToastifyService.error("Oops! Something Went Wrong");
    } finally {
      setLoading({
        isLoading: false,
        value: "updateTaskRule",
      });
    }
  };
  const handleTestQuery = async () => {
    setLoading({
      isLoading: true,
      value: "testQuery",
    });
    const generatedSqlQuery = formatQuery(query, "sql");
    try {
      const queryResponse = await axiosPrivate.post(
        `TaskEngine/validate-task-bucket-query`,
        {
          taskBucketQuery:
            "select * from inventories where" + generatedSqlQuery,
        }
      );
      setShowTestQueryTable({
        showBool: true,
        queryResponse: queryResponse?.data?.result,
      });
    } catch (err) {
      console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "testQuery",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "testQuery",
      });
    }
  };
  return (
    <React.Fragment>
      <TestQueryTable
        show={showTestQueryTable.showBool}
        setShowTestQueryTable={setShowTestQueryTable}
        queryResponse={showTestQueryTable?.queryResponse}
      />
      <Offcanvas show={props.show} onHide={() => props?.SetShowUpdateRule({ showBool: false, taskRuleId: "" })} placement="end" className="w-60">
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">Update Rule</Offcanvas.Title>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => props?.SetShowUpdateRule({ showBool: false, taskRuleId: "" })}></button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="custom-tab-body">
            <Row>
              <Col xl={4}>
                <div className="mb-4">
                  <Form.Label>
                    Task Bucket Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Task Name"
                    onChange={handleChange}
                    name="taskBucketName"
                    value={formData.taskBucketName}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={5}>
                <div className="mb-4">
                  <Form.Label>
                    Task Bucket Instruction{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Task Bucket Instruction"
                    rows="3"
                    onChange={handleChange}
                    name="taskBucketInstructions"
                    value={formData.taskBucketInstructions}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={4}>
                <div className="mb-4">
                  <Form.Label>Task Group</Form.Label>
                  <Select
                    options={userGroupList}
                    isSearchable={true}
                    value={userGroupList.find(
                      (option) => option.value === formData.userGroup
                    )}
                    onChange={(selectedOption) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        userGroup: selectedOption.value,
                      }));
                    }}
                    name="userGroup"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="mb-4">
                  {query?.rules?.length > 0 && (
                    <>
                      <div className="mb-3 bg-gray-800 p-2 rounded w-100">
                        <h6 className="text-white fs-14 lh-5">{testQuery}</h6>
                      </div>
                      <h3 className="fs-14 mb-3">Rule Condition</h3>
                      <QueryBuilder
                        fields={fields}
                        query={query}
                        onQueryChange={handleQueryChange}
                      />
                      <Button
                        variant="dark"
                        className="mt-3"
                        onClick={handleTestQuery}
                        disabled={
                          loading.isLoading && loading?.value === "testQuery"
                        }
                      >
                        {loading.isLoading && loading?.value === "testQuery" ? (
                          <LoadingSpinner
                            color="#ffffff"
                            size={30}
                            type={"TailSpin"}
                          />
                        ) : (
                          "Test Query"
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Form.Select
            className="wt-150 me-2"
            onChange={handleChange}
            value={formData.status}
            name="status"
          >
            <option disabled>Choose Status</option>
            {taskRuleStatus?.map((trstts) => (
              <option value={trstts.id}>{trstts.value}</option>
            ))}
          </Form.Select>
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "updateTaskRule"}
            onClick={updateTaskRule}
          >
            {loading.isLoading && loading?.value === "updateTaskRule" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Update Rule</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
});
