import React, { useContext, useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import {
  Card,
  Row,
  Col,
  Nav,
  ProgressBar,
  Table,
  Badge,
  Button,
  Dropdown,
  Form,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PayerClosedModal from "../payer-closed-modal";
import WorkLaterModal from "../work-later-modal";
import TLQueryModal from "../TL-query-modal";
import MyCasesFilters from "../my-casses-filter";
import MyTasksDetails from "../Details/my-task-details";
import EmptyImg from "../../../assets/img/empty-state/empty.png";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import DataTable from "react-data-table-component";
import ToastifyService from "../../_common/ToastifyService";
import ShimmerLoader from "../../_common/ShimmerLoader";
import LoadingSpinner from "../../_common/LoadingSpinner";
import { TaskContext } from "../../../layouts/TaskContext";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export default function MyTask2() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [myTask, setMyTask] = useState({});
  const [decisionTreeList, setDecisionTreeList] = useState({});
  const [pendingTasks, setPendingTasks] = useState([]);
  const [teamLeadQueries, setTeamLeadQueries] = useState([]);
  const [parkedTasks, setParkedTasks] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showPayerClosed, setShowPayerClosed] = useState({
    showBoolPayerClosed: false,
    taskId: "",
    visitId: "",
  });
  const [showWorkLater, setShowWorkLater] = useState({
    showBoolWorkLater: false,
    taskId: "",
    visitId: "",
  });
  const [showTlQuery, setShowTlQuery] = useState({
    showBoolTLQuery: false,
    taskId: "",
    visitId: "",
  });
  const { setPendingTasksLength } = useContext(TaskContext);
  // const [showCaseFilter, setShowCaseFilter] = useState(false);
  const [showTasksDetails, setShowTasksDetails] = useState({
    showTaskDetails: false,
    taskId: "",
  });
  const [workflowComponentList, setWorkflowComponentList] = useState([]);

  useEffect(() => {
    if (auth?.id) {
      getMyTask();
    }
  }, [auth?.id]);

  useEffect(() => {
    setPendingTasksLength(pendingTasks.length); // Update context value
  }, [pendingTasks, setPendingTasksLength]);

  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const getMyTask = async () => {
    try {
      setLoading({ isLoading: true, value: "getMyTask" });
      const myTaskListData = await axiosPrivate.get(
        `Task/my-tasks/${auth?.id}`
      );
      setMyTask(myTaskListData?.data?.result);
      setPendingTasks(myTaskListData?.data?.result?.pendingTasks);
      setTeamLeadQueries(myTaskListData?.data?.result?.tlQueries);
      setParkedTasks(myTaskListData?.data?.result?.parkedTasks);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getMyTask" });
    } finally {
      setLoading({ isLoading: false, value: "getMyTask" });
    }
  };
  // const RequestNewTask = async () => {
  //   try {
  //     setLoading({ isLoading: true, value: "RequestNewTask" });
  //     const RequestPendingList = await axiosPrivate.post(
  //       `Task/assign-new-task/${auth.id}`
  //     );
  //     setMyTask(RequestPendingList?.data?.result);
  //     setTaskSummary(
  //       RequestPendingList?.data?.result?.taskBucketSummary?.taskBucketDetails
  //     );
  //     setPendingTasks(RequestPendingList?.data?.result?.pendingTasks);
  //     setTeamLeadQueries(RequestPendingList?.data?.result?.tlQueries);
  //     setParkedTasks(RequestPendingList?.data?.result?.parkedTasks);
  //   } catch (err) {
  //     console.error(err);
  //     setLoading({ isLoading: false, value: "RequestNewTask" });
  //     ToastifyService.error("Oops! Something Went Wrong");
  //   } finally {
  //     setLoading({ isLoading: false, value: "RequestNewTask" });
  //   }
  // };

  // const totalCases = taskBucketSummary.reduce(
  //   (total, item) => total + item.taskCount,
  //   0
  // );

  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row?.visitId,
      sortable: true,
      cell: (row) => (
        <Link
          className="fw-semibold"
          onClick={() =>
            setShowTasksDetails({
              showTaskDetails: true,
              taskId: row.id,
            })
          }
        >
          {row?.visitId}
        </Link>
      ),
    },
    {
      name: "Ref. ID",
      selector: (row) => row?.referenceId,
      sortable: true,
      cell: (row) => <div>{row?.referenceId}</div>,
    },
    {
      name: "Patient Name",
      selector: (row) => row?.patientName,
      sortable: true,
      cell: (row) => <div>{row?.patientName}</div>,
    },
    // {
    //   name: "Charge Amount",
    //   selector: (row) => row?.chargeAmount,
    //   sortable: true,
    //   cell: (row) => <div>{row?.chargeAmount}</div>,
    // },
    // {
    //   name: "Current Balance",
    //   selector: (row) => row?.currentBalance,
    //   sortable: true,
    //   cell: (row) => <div>{row?.currentBalance}</div>,
    // },
    {
      name: "Insurance Balance",
      selector: (row) => row?.insuranceBalance,
      sortable: true,
      cell: (row) => <div>{row?.insuranceBalance} </div>,
    },
    {
      name: "Last bill date",
      selector: (row) =>
        row.lastBillDate === "0001-01-01T00:00:00"
          ? "-"
          : new Date(row?.lastBillDate).toLocaleString(),
      sortable: true,
      cell: (row) => (
        <div>
          {row.lastBillDate === "0001-01-01T00:00:00"
            ? "-"
            : new Date(row?.lastBillDate).toLocaleString()}
        </div>
      ),
    },
    // {
    //   name: "Task Bucket",
    //   selector: (row) => row?.taskBucketName,
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       {" "}
    //       <Badge className="badge-pill" bg="primary">
    //         {row.taskBucketName}
    //       </Badge>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Priority",
    //   selector: (row) => row?.taskPriority,
    //   sortable: true,
    //   cell: (row) => <div className="fw-bold">{row.taskPriority}</div>,
    // },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-dark"
            type="button"
            className="btn-icon me-2"
            onClick={() =>
              setShowTasksDetails({
                showTaskDetails: true,
                taskId: row.id,
              })
            }
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const filteredData = pendingTasks?.filter((item) => {
    return (
      item?.visitId?.toString().includes(searchText.toLowerCase()) ||
      item?.referenceId?.toString().includes(searchText.toLowerCase()) ||
      item?.patientName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.currentBalance
        ?.toLocaleString()
        .includes(searchText.toLowerCase()) ||
      // item?.taskBucketName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskpriority?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  useEffect(() => {
    getDecisionTreeList();
  }, []);
  const getDecisionTreeList = async () => {
    try {
      setLoading({ isLoading: true, value: "getDecisionTreeList" });
      const decisionTreeListData = await axiosPrivate.get(
        `Setting/get-disposition-list`
      );
      console.log("DecisionTreeListData", decisionTreeListData?.data);
      setDecisionTreeList(decisionTreeListData?.data);
    } catch (err) {
      console.error("Error fetching decision tree list:", err);
    } finally {
      setLoading({ isLoading: false, value: "getDecisionTreeList" });
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  return (
    <React.Fragment>
      <PayerClosedModal
        show={showPayerClosed?.showBoolPayerClosed}
        closeFunction={setShowPayerClosed}
        taskId={showPayerClosed?.taskId}
        visitId={showPayerClosed?.visitId}
        setTeamLeadQueries={setTeamLeadQueries}
        // setTaskSummary={setTaskSummary}
        setPendingTasks={setPendingTasks}
        setParkedTasks={setParkedTasks}
        setMyTask={setMyTask}
        setShowTasksDetails={setShowTasksDetails}
      />
      <WorkLaterModal
        show={showWorkLater.showBoolWorkLater}
        closeFunction={setShowWorkLater}
        taskId={showWorkLater.taskId}
        visitId={showWorkLater.visitId}
        setTeamLeadQueries={setTeamLeadQueries}
        // setTaskSummary={setTaskSummary}
        setPendingTasks={setPendingTasks}
        setParkedTasks={setParkedTasks}
        setMyTask={setMyTask}
        setShowTasksDetails={setShowTasksDetails}
      />
      <TLQueryModal
        show={showTlQuery.showBoolTLQuery}
        closeFunction={setShowTlQuery}
        taskId={showTlQuery.taskId}
        visitId={showTlQuery.visitId}
        setTeamLeadQueries={setTeamLeadQueries}
        // setTaskSummary={setTaskSummary}
        setPendingTasks={setPendingTasks}
        setParkedTasks={setParkedTasks}
        setMyTask={setMyTask}
        setShowTasksDetails={setShowTasksDetails}
      />
      {/* <MyCasesFilters
        show={showCaseFilter}
        closeFunction={setShowCaseFilter}
        setPendingTasks={setPendingTasks}
      /> */}
      <MyTasksDetails
        show={showTasksDetails.showTaskDetails}
        setShowTasksDetails={setShowTasksDetails}
        taskId={showTasksDetails.taskId}
        setTeamLeadQueries={setTeamLeadQueries}
        // setTaskSummary={setTaskSummary}
        setPendingTasks={setPendingTasks}
        setParkedTasks={setParkedTasks}
        setMyTask={setMyTask}
        workflowComponentList={workflowComponentList}
        decisionTreeList={decisionTreeList}
      />
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            {/* <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol> */}
            <h4 className="main-title mb-0">My Task</h4>
          </div>
          {/* <div>
            <Button
              variant="danger"
              className="d-flex align-items-center justify-content-center gap-1"
              disabled={
                loading.isLoading && loading?.value === "RequestNewTask"
              }
              onClick={() => RequestNewTask()}
            >
              {loading.isLoading && loading?.value === "RequestNewTask" ? (
                <LoadingSpinner color="#ffffff" size={25} type={"TailSpin"} />
              ) : (
                <>
                  <i className="ri-refresh-line fs-18 lh-1"></i>
                  <span className="d-sm-inline">Requests New Tasks</span>
                </>
              )}
            </Button>
          </div> */}
        </div>

        <Row className="g-3 pb-5">
          <Col md={3}>
            <Row className="g-3">
              <Col md={12}>
                {teamLeadQueries?.length > 0 && (
                  <Card className="card-one h-auto mb-3">
                    <Card.Header>
                      <Card.Title as="h6">TL Query</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <ul className="people-group">
                        {teamLeadQueries?.length ? (
                          teamLeadQueries?.map((itm) => (
                            <li className="people-item bg-pink-4f0">
                              <div className="people-body">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h6 className="fs-12 fw-bold">
                                    <Link
                                      onClick={() =>
                                        setShowTasksDetails({
                                          showTaskDetails: true,
                                          taskId: itm?.taskId,
                                        })
                                      }
                                    >
                                      Visit ID: {itm?.visitId}
                                    </Link>
                                  </h6>

                                  <Badge
                                    className={`badge-pill text-white ${itm?.statusDesc === "Pending"
                                        ? "bg-danger"
                                        : "bg-success"
                                      }`}
                                  >
                                    {itm?.statusDesc}
                                  </Badge>
                                </div>

                                <h6
                                  className="text-dark mb-2"
                                  style={{
                                    display:
                                      itm?.statusDesc === "Pending"
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  <b>TL Response: </b>
                                  <span className="d-block">
                                    {itm?.teamLeadRemark}
                                  </span>
                                </h6>

                                <h6 className="text-dark">
                                  <b>You: </b>{" "}
                                  <span className="d-block">{itm.remark}</span>{" "}
                                </h6>
                              </div>
                            </li>
                          ))
                        ) : (
                          <p className="p-3 text-center">
                            No TL query to display
                          </p>
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                )}

                {parkedTasks?.length > 0 && (
                  <Card className="card-one h-auto mb-3">
                    <Card.Header>
                      <Card.Title as="h6">Parked Cases</Card.Title>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <ul className="people-group">
                        {parkedTasks?.length ? (
                          parkedTasks?.map((itm) => (
                            <li className="people-item">
                              <div className="people-body">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h6 className="fs-12 fw-bold">
                                    <Link
                                      onClick={() =>
                                        setShowTasksDetails({
                                          showTaskDetails: true,
                                          taskId: itm?.taskId,
                                        })
                                      }
                                    >
                                      Visit ID: {itm?.visitId}
                                    </Link>{" "}
                                  </h6>
                                  <Badge bg="light" className="badge-pill">
                                    {itm?.statusDesc}
                                  </Badge>
                                </div>
                                <span className="text-dark mb-2">
                                  {itm?.remark}
                                </span>
                              </div>
                            </li>
                          ))
                        ) : (
                          <p className="p-3 text-center">
                            No parked tasks to display
                          </p>
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                )}
                <Card className="card-one h-auto">
                  <Card.Header>
                    <Card.Title as="h6">Task Bucket Summary</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      {/* <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link> */}
                      {/* <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link> */}
                    </Nav>
                  </Card.Header>

                  <Card.Body className="p-3">
                    <div className="d-flex align-items-center justify-content-between mb-2 border-bottom">
                      <label className="card-title fs-sm fw-medium">Pending Cases</label>
                      <h2 className="performance-value fs-16 fw-bold mb-0">{myTask?.taskBucketSummary?.pendingQueriesCount}</h2>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2 border-bottom">
                      <label className="card-title fs-sm fw-medium">Transfered To TL</label>
                      <h2 className="performance-value fs-16 fw-bold mb-0">{myTask?.taskBucketSummary?.transferedToTLQueriesCount}</h2>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-2 border-bottom">
                      <label className="card-title fs-sm fw-medium">Payer Closed</label>
                      <h2 className="performance-value fs-16 fw-bold mb-0">{myTask?.taskBucketSummary?.payerClosedQueriesCount}</h2>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                      <label className="card-title fs-sm fw-medium">Worked Later</label>
                      <h2 className="performance-value fs-16 fw-bold mb-0">{myTask?.taskBucketSummary?.workLaterQueriesCount}</h2>
                    </div>

                    {/* <ProgressBar className="progress-one ht-8 mt-2 mb-4"> */}
                    {/* {taskBucketSummary.map((item) => {
                        const progress = (item?.taskCount / totalCases) * 100;
                        const color =
                          item.color || (item.color = getRandomColor());

                        return (
                          <ProgressBar
                            now={progress}
                            style={{ backgroundColor: color }}
                            key={item.taskBucketName}
                          />
                        );
                      })} */}
                    {/* </ProgressBar> */}
                    {/* <Table className="table-three">
                      <tbody> */}
                    {/* {taskBucketSummary.map((item) => {
                          const color = item.color || getRandomColor();
                          return (
                            <tr key={item.taskBucketName}>
                              <td>
                                <div
                                  className="badge-dot"
                                  style={{ backgroundColor: color }}
                                ></div>
                              </td>
                              <td>{item.taskBucketName}</td>
                              <td>{item.taskCount}</td>
                            </tr>
                          );
                        })} */}
                    {/* </tbody>
                    </Table> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={9}>
            <Row className="g-3 h-100">
              <Col xl="12" className="h-100">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Pending Tasks</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      {/* <div className="table-filter me-3">
                        <Button
                          variant="outline-secondary filter-bttn"
                          onClick={() => setShowCaseFilter(true)}
                        >
                          <i className="ri-error-warning-fill filter-pill text-danger"></i>
                          <i className="ri-equalizer-line align-middle"></i>
                          <span className="align-middle">Filter</span>
                        </Button>
                      </div> */}

                      <Form.Control
                        type="text"
                        className="search-filter-input wt-200"
                        value={searchText}
                        onChange={handleSearch}
                        placeholder="Filter..."
                      />
                    </Nav>
                  </Card.Header>
                  <Card.Body className="p-3">
                    {loading.isLoading && loading.value === "getMyTask" ? (
                      <>
                        <ShimmerLoader
                          colNames={columns?.map((column) => column.name)}
                          colsCount={1}
                        />
                      </>
                    ) : (
                      <>
                        {pendingTasks.length !== 0 ? (
                          <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            paginationPerPage={10}
                          />
                        ) : (
                          <div className="empty-state">
                            <div>
                              <div>
                                <Image
                                  src={EmptyImg}
                                  className="wt-120 mb-3"
                                  alt="empty"
                                />
                              </div>

                              <div className="text-center">
                                <h3 className="fs-16 text-secondary opacity-75 fw-fw-semibold mb-1">
                                  No Pending Cases{" "}
                                </h3>
                                <h5 className="fs-14 text-secondary opacity-50 mb-3">
                                  You dont have any cases pending. Lets go ahead
                                  and request for new cases.
                                </h5>
                                {/* <Button
                                  variant="danger"
                                  className="d-flex align-items-center justify-content-center gap-1"
                                  onClick={() => RequestNewTask()}
                                >
                                  <i className="ri-refresh-line fs-18 lh-1"></i>
                                  <span className="d-sm-inline">
                                    Requests New Tasks
                                  </span>
                                </Button> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
{
  /* <Dropdown className="me-2">
  <Dropdown.Toggle
    variant="dark"
    className="custom-dropdown-action ht-30 pe-2 ps-2 pt-0 pb-0"
    id="dropdown-basic"
  >
    Park
  </Dropdown.Toggle>
  <Dropdown.Menu>
    <Dropdown.Item
      onClick={() =>
        setShowPayerClosed({
          showBoolPayerClosed: true,
          taskId: row?.id,
          visitId: row.visitID,
        })
      }
    >
      Payer closed
    </Dropdown.Item>
    <Dropdown.Item
      onClick={() =>
        setShowWorkLater({
          showBoolWorkLater: true,
          taskId: row.id,
          visitId: row.visitID,
        })
      }
    >
      Work later
    </Dropdown.Item>
    <Dropdown.Item
      onClick={() =>
        setShowTlQuery({
          showBoolTLQuery: true,
          taskId: row.id,
          visitId: row.visitID,
        })
      }
    >
      TL Query
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>;  */
}

{
  /* <li className="people-item">
          <div className="people-body">
              <div className="d-flex align-items-center justify-content-between">
                  <h6 className="fs-12 fw-bold"><Link onClick={() => handleTaskDetails(true)}>Visit ID: 10322</Link> </h6>
                  <Badge bg="light" className="badge-pill">Payer Closed</Badge>
              </div>
              <span className="text-dark mb-2">This is a sample comment written during the park process</span>
          </div>
  </li> */
}

{
  /* <li className="people-item">
  <div className="people-body">
    <div className="d-flex align-items-center justify-content-between">
        <h6 className="fs-12 fw-bold"><Link onClick={() => handleTaskDetails(true)}>Visit ID: 10322</Link></h6>
        <Badge bg="danger" className="badge-pill text-white">Pending</Badge>
    </div>
    <span className="text-dark"><b>You: </b> <br /> This is the question from the agent </span>
  </div>
</li> */
}
