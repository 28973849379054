import React, { useEffect, useState } from "react";
import { Form, Card, Dropdown } from "react-bootstrap";
import ManuallyAssignComponent from "./manually-assign";
import DataTable from "react-data-table-component";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function AssignedAssignment(props) {
  const [showManuallyAssign, setShowManuallyAssign] = useState(false);
  const [PendingInventoryList, setpendingInventory] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setpendingInventory(props?.PendingInventoryList);
  }, [props?.PendingInventoryList]);

  const ManuallyAssign = (state) => {
    setShowManuallyAssign(state);
  };
  const [selectedTask, setSelectedTask] = useState([]);

  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row.visitfid,
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-start">
          <div>{row.visitfid}</div>
        </div>
      ),
    },
    {
      name: "Ref. ID",
      selector: (row) => row.limS_CASEFILE_ID,
      sortable: true,
      cell: (row) => <div>{row.limS_CASEFILE_ID}</div>,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patienT_FIRST_NAME +' '+ row.patienT_LAST_NAME,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 mb-1 fw-bold">{row.patienT_FIRST_NAME +' '+ row.patienT_LAST_NAME}</h5>
        </div>
      ),
    },
    {
      name: "Last Denial Codes",
      selector: (row) => row.lasT_DENIAL_CODES,
      sortable: true,
      cell: (row) => (
        <div>
          <b>{row.lasT_DENIAL_CODES}</b>
        </div>
      ),
    },
    {
      name: "Policy Number",
      selector: (row) => row.policY_NUMBER,
      sortable: true,
      cell: (row) => (
        <div>
          {row.policY_NUMBER}
        </div>
      ),
    },
    {
      name: "Bill Amount",
      selector: (row) => row.billprice,
      sortable: true,
      cell: (row) => (
        <div>
          <b>$ {row.billprice}</b>
        </div>
      ),
    },
    {
      name: "Lims Date Of Service",
      selector: (row) =>
        row.limS_DATE_OF_SERVICE === "0001-01-01T00:00:00"
          ? "-"
          : new Date(row?.limS_DATE_OF_SERVICE).toLocaleString(),
      sortable: true,
      cell: (row) => (
        <div>
          {row.limS_DATE_OF_SERVICE === "0001-01-01T00:00:00"
            ? "-"
            : new Date(row?.limS_DATE_OF_SERVICE).toLocaleString()}
        </div>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = PendingInventoryList?.filter((item) => {
    return (
      item?.visitfid?.toString().includes(searchText.toLowerCase()) ||
      item?.limS_CASEFILE_ID?.toString().includes(searchText.toLowerCase()) ||
      (item?.patienT_FIRST_NAME +' '+item.patienT_LAST_NAME)?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.lasT_DENIAL_CODES?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.policY_NUMBER?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.billprice?.toString().includes(searchText.toLowerCase()) ||
      new Date(item?.limS_DATE_OF_SERVICE)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });
  // setpendingInventory(filteredData)
  return (
    <React.Fragment>
      <ManuallyAssignComponent
        show={showManuallyAssign}
        closeFunction={ManuallyAssign}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        setpendingInventory={setpendingInventory}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Assigned Cases</h4>
        </div>

        <div className="d-flex align-items-center gap-3">
          <Dropdown className="me-2">
            <Dropdown.Toggle
              variant="primary"
              className="custom-dropdown-action ht-30 pe-2 ps-2 pt-0 pb-0"
              id="dropdown-basic"
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => ManuallyAssign(true)}>
                Manually Assign
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {props?.loading?.isLoading &&
          props?.loading?.value === "getAssignedInventory" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
