import axios from "axios";

// const BASE_URL_HOSTED = "https://arworkflow.innovalglobal.com:802/api/";
const BASE_URL_HOSTED = "https://argateway.cxengine.net/api/";
const BASE_URL_LOCAL = "https://localhost:44387/api/";
const mode = "";
export default axios.create({
  baseURL: mode === "dev" ? BASE_URL_LOCAL : BASE_URL_HOSTED,
});

export const axiosPrivate = axios.create({
  baseURL: mode === "dev" ? BASE_URL_LOCAL : BASE_URL_HOSTED,
  headers: { "Content-Type": "application/json" },
});
