import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Image } from "react-bootstrap";
import { axiosPrivate } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function DocumentUpload(props) {
  const { auth } = useAuth();
  const [documentList, setDocumentList] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [file, setFile] = useState(null);
  const [documentFormat, setDocumentFormat] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [pages, setPages] = useState("");
  const taskActionSts = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "DOCTYP"
  )?.fieldValues;
  const DOCFormat = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "DOCFOR"
  )?.fieldValues;

  useEffect(() => {
    setDocumentList(props?.taskDetails?.caseDocumentUploads);
  }, [props?.taskDetails]);

  const handleDeleteFile = async (id) => {
    try {
      const response = await axiosPrivate.post(`Task/delete-case-doc/${id}`);
      setDocumentList(response?.data?.result);
      closeModal();
      ToastifyService.success("Document Deleted successfully");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDownloadFile = (data) => {
    const extension =
      data.documentFormatId === "b163f695-4e3e-4aba-bdb5-946432b83a22"
        ? "pdf"
        : "jpeg";
    // Create a new anchor element
    const link = document.createElement("a");

    // Set the href attribute to the file URL
    link.href = `https://freshlo.oss-ap-south-1.aliyuncs.com/IClaim/CaseDocs/${data.id}.${extension}`;

    // Set the download attribute to the desired file name
    link.download = data.fileName;

    // Append the anchor element to the document body
    document.body.appendChild(link);
    // Set the target attribute to open the link in a new tab
    link.target = "_blank";
    // Programmatically click the anchor element to trigger the download
    link.click();

    // Remove the anchor element from the document body
    document.body.removeChild(link);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadDoc = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const formData = new FormData();
    formData.append("file", file); // Assuming 'file' is a file object in your state
    formData.append("CaseId", props?.showUploadDocModal?.caseId);
    formData.append(
      "TaskBucketQueueId",
      props?.showUploadDocModal?.taskBucketQueueId
    );
    formData.append("VisitId", props?.showUploadDocModal?.visitId);
    formData.append("FileName", file.name); // You should pass the file name as a string
    formData.append("Pages", String(pages)); // Assuming 'pages' is in your state
    formData.append("DocumentTypeId", documentType); // Assuming 'documentType' is in your state
    formData.append("DocumentFormatId", documentFormat); // Assuming 'documentType' is used for both DocumentTypeId and DocumentFormatId
    formData.append("UploadedBy", auth.id); // Assuming 'auth.id' contains the uploader's ID

    try {
      setLoading({
        isLoading: true,
        value: "UploadDoc",
      });
      const response = await axiosPrivate.post(
        "Task/upload-case-doc",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPages("");
      setDocumentList(response?.data?.result);
      closeModal();
      ToastifyService.success("Document uploaded successfully");
    } catch (error) {
      console.error(error);
      ToastifyService.error("Oops! Something Went Wrong");
      setPages("");
      setLoading({
        isLoading: false,
        value: "UploadDoc",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "UploadDoc",
      });
    }
  };
  const closeModal = () => {
    props?.setShowUploadDocModal(false);
    setDocumentType("");
    setDocumentFormat("");
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16 main-title mb-0">Uploaded Documents</h4>
        </div>
      </div>

      <div className="table-responsive">
        <Table className="mb-0">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Document Type</th>
              <th>Pages</th>
              <th>Visit #</th>
              <th>Uploaded On/By</th>
              <th>Task Queue</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {documentList?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link className="d-flex align-items-center">
                    <Image src={item.icon} className="wt-16" />
                    <h5 className="fs-14 ms-2">{item.fileName}</h5>
                  </Link>
                </td>
                <td>{item.documentTypeName}</td>
                <td>{item.pages}</td>
                <td>{item.visitId}</td>
                <td>
                  <h5 className="fs-14 mb-1">{item.modifiedOn}</h5>
                  <h6 className="fs-12">{item.modifiedByName}</h6>
                </td>
                <td>{item.taskBucketName}</td>
                <td className="w-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="outline-dark"
                      type="button"
                      className="btn-icon me-2"
                      onClick={() => handleDownloadFile(item)}
                    >
                      <i className="ri-download-line"></i>
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="btn-icon"
                      onClick={() => handleDeleteFile(item.id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* <Modal
        centered
        show={props?.showUploadDocModal}
        onHide={() => props?.setShowUploadDocModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-14">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Form.Label>Choose a File</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </div>
          <div className="mb-3">
            <Form.Label>Document Type</Form.Label>
            <Form.Select onChange={(e) => setDocumentType(e.target.value)}>
              {taskActionSts && taskActionSts.length > 0 ? (
                taskActionSts.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.value}
                  </option>
                ))
              ) : (
                <option selected> No options available</option>
              )}
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label>Document Format</Form.Label>
            <Form.Select onChange={(e) => setDocumentFormat(e.target.value)}>
              {DOCFormat && DOCFormat.length > 0 ? (
                DOCFormat.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.value}
                  </option>
                ))
              ) : (
                <option selected> No options available</option>
              )}
            </Form.Select>
          </div>

          <div className="mb-3">
            <Form.Label>Pages</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Pages"
              value={pages}
              onChange={(e) => setPages(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props?.setShowUploadDocModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUploadDoc}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        centered
        show={props?.showUploadDocModal}
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-14">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Form.Label>Document Format</Form.Label>
            <Form.Select
              value={documentFormat}
              onChange={(e) => {
                setDocumentFormat(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Choose Document Format
              </option>
              {/* {DOCFormat && DOCFormat.length > 0 ? ( */}
              {DOCFormat?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item?.value}
                </option>
              ))}
            </Form.Select>
          </div>
          {documentFormat !== "" && (
            <>
              <div className="mb-3">
                <Form.Label>Document Type</Form.Label>
                <Form.Select onChange={(e) => setDocumentType(e.target.value)}>
                  {taskActionSts && taskActionSts.length > 0 ? (
                    taskActionSts.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.value}
                      </option>
                    ))
                  ) : (
                    <option selected> No options available</option>
                  )}
                </Form.Select>
              </div>
              <div className="mb-3">
                <Form.Label>Choose a File</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept={
                    documentFormat === "b163f695-4e3e-4aba-bdb5-946432b83a22"
                      ? "application/pdf"
                      : "image/jpeg"
                  }
                />
              </div>

              <div className="mb-3">
                <Form.Label>Pages</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Pages"
                  value={pages}
                  onChange={(e) => setPages(e.target.value)}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDoc}
            disabled={loading.isLoading && loading?.value === "UploadDoc"}
          >
            {loading.isLoading && loading?.value === "UploadDoc" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
