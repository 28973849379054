import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function DebialCorerespondence(props) {
  return (
    <React.Fragment>
      <div className="claim-information-wrapper">
        <Card className="mb-3">
          <span className="divider-name">Debial/Corerespondence</span>
          <Card.Body className="pt-4 pb-4">
            <Row className="g-3">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Most Recent Deposit Date</h5>
                <h4 className="fw-bold text-dark fs-14">
                {props?.taskDetails?.mostrecentdepositdate ===
                  "0001-01-01T00:00:00"
                    ? "-"
                    : new Date(
                        props?.taskDetails?.mostrecentdepositdate
                      ).toLocaleString()}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">RARC Description</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.rarC_DESCRIPTION === null ||
                  props?.taskDetails?.rarC_DESCRIPTION === ""
                    ? "-"
                    : props?.taskDetails?.rarC_DESCRIPTION}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Remark Priority Category</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.remarK_PRIORITY_CATEGORY === null ||
                  props?.taskDetails?.remarK_PRIORITY_CATEGORY === ""
                    ? "-"
                    : props?.taskDetails?.remarK_PRIORITY_CATEGORY}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">All Denial Codes</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.alldenialcodes === null ||
                  props?.taskDetails?.alldenialcodes === ""
                    ? "-"
                    : props?.taskDetails?.alldenialcodes}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Last Denial Codes</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.lasT_DENIAL_CODES === null ||
                  props?.taskDetails?.lasT_DENIAL_CODES === ""
                    ? "-"
                    : props?.taskDetails?.lasT_DENIAL_CODES}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
