import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function LabReferringProviderInfo(props) {
  return (
    <React.Fragment>
      <div className="claim-information-wrapper">
        <Card className="mb-3">
          <span className="divider-name">
            LAB/Referring Provider Information
          </span>
          <Card.Body className="pt-4 pb-4">
            <Row className="g-3">
              <Col xl={2}>
                <h5 className="fs-14 mb-1">BS Ordering Lab</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.bS_ORDERING_LAB === null ||
                  props?.taskDetails?.bS_ORDERING_LAB === ""
                    ? "-"
                    : props?.taskDetails?.bS_ORDERING_LAB}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">BS Performing Lab</h5>
                <h4 className="fw-bold text-dark fs-14">-</h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Physician First Name</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.phY_FIRST_NAME === null ||
                  props?.taskDetails?.phY_FIRST_NAME === ""
                    ? "-"
                    : props?.taskDetails?.phY_FIRST_NAME}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Physician Last Name</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.phY_LAST_NAME === null ||
                  props?.taskDetails?.phY_LAST_NAME === ""
                    ? "-"
                    : props?.taskDetails?.phY_LAST_NAME}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">NPI Number</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.npI_NUMBER === null ||
                  props?.taskDetails?.npI_NUMBER === ""
                    ? "-"
                    : props?.taskDetails?.npI_NUMBER}
                </h4>
              </Col>
              <Col xl={2}>
                <h5 className="fs-14 mb-1">Clinic State</h5>
                <h4 className="fw-bold text-dark fs-14">
                  {props?.taskDetails?.cliniC_STATE === null ||
                  props?.taskDetails?.cliniC_STATE === ""
                    ? "-"
                    : props?.taskDetails?.cliniC_STATE}
                </h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
